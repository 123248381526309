<template>
	<div class="layout-menu-container">
		<AppSubmenu :items="menu" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
	data() {
		return {
			menu : [
                {
                    label: 'MENU UTAMA',
                    items: [
                        {label: 'Dashboard', icon: 'dashboard', to: '/dashboard'},
						{label: 'Manajemen User', icon: 'users', to: '/user'},
						{label: 'Customer', icon: 'customer', to: '/customer'},
						{label: 'Transaksi', icon: 'transaction', to: '/transaction'},
                        {label: 'Manajemen Content', icon: 'content', to: '/content-management'},
						{label: 'Log Audit', icon: 'content', to: '/log-audit'},
                    ]
                },
				{
					label: 'LAINNYA',
					items: [
						{label: 'Pengaturan', icon: 'settings', to: '/settings'},
						{
							label: 'Master Data', icon: 'master-data',
							items: [
								{label: 'Produk', icon: '', to: '/master-data/product'},
								{label: 'Denom', icon: '', to: '/master-data/denom'},
								{label: 'Prefix', icon: '', to: '/master-data/prefix'},
								{label: 'Bank', icon: '', to: '/master-data/bank'},
							],
						},
						{
							label: 'Data & Assets', icon: 'data-assets',
							items: [
								{label: 'Country', icon: '', to: '/data-assets/country'},
								{label: 'Province', icon: '', to: '/data-assets/province'},
								{label: 'City', icon: '', to: '/data-assets/city'},
								{label: 'District', icon: '', to: '/data-assets/district'},
							],
						},
						{label: 'Rc Mapping', icon: 'settings', to: '/rc-mapping'},
					]
				},
            ],
		}
	},
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
		onKeyDown(event) {
			const nodeElement = event.target;
			if (event.code === 'Enter' || event.code === 'Space') {
				nodeElement.click();
				event.preventDefault();
			}
		},
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	},
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>